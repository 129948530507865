.program {
  position: relative;
  padding-top: 2.5em;
  padding-bottom: 5em;
  background-color: $dark-grey;

  .cont {
    position: relative;
    z-index: 1; }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 50%;
    height: 100%;
    border-radius: 0 1em 1em 0;
    background: $color-red; }

  @include media-tablet {
    padding-top: 5em; }

  @include media-tablet-h {
    &::after {
      display: block; } }

  &__header {
    margin-bottom: 2.5em;

    @include media-tablet {
      margin-bottom: 3.125em; }

    @include media-desktop {
      margin-bottom: 2.5em; } }

  &__title {
    @include h36;
    color: #fff;

    @include media-desktop {
      @include h48; } }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    @include lreset;

    @include media-tablet-h {
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 2rem;
      justify-content: center;

      @include media-desktop-1440 {
        flex-wrap: nowrap; } } }

  &__item {
    padding: 1.5rem;
    border-radius: 1.25rem;
    background: #fff;

    @include media-tablet-h {
      flex: auto;
      max-width: 460px; }

    @include media-desktop-1440 {
      flex: 1 1;
      padding: 2.5rem; }

    &-content {
      @include media-tablet-h {
        display: flex;
        flex-direction: column;
        height: 100%; } }

    &-top {
      display: flex;
      align-items: center;
      column-gap: 1em;
      margin-bottom: 1.5em;

      hr {
        height: 1.4375em;
        width: 1px;
        margin: 0;
        border: none;
        background-color: $dark-grey; } }

    &-date {
      @include fs18; }

    &-time {
      @include fs18;
      font-weight: 700;

      span {
        position: relative;
        top: -0.5em;
        font-size: 0.7778em;
        text-transform: uppercase; } }

    &-title {
      display: block;
      margin-bottom: 0.75em;
      @include h18;

      &:hover {
        color: $red; } }

    &-text {
      @include fs14;
      margin-bottom: 1.5em; }

    &-avatars {
      display: flex;
      column-gap: 1em;

      @include media-tablet-h {
        margin-top: auto; }

      &-avatar {
        width: 2.5em;
        height: 2.5em;

        img {
          @include round-img; } } } } }
