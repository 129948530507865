.footer {
  padding-top: 2.625em;
  padding-bottom: 2.625em;
  overflow: hidden;
  color: white;
  background-color: #000000;

  .cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-tablet-h {
      padding-left: 1.875em;
      padding-right: 1.875em; }

    @include media-desktop {
      flex-wrap: nowrap; }

    @include media-desktop-1600 {
      padding-left: 2.5em;
      padding-right: 2.5em; } }

  &__logo {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 1.1em;
    margin-bottom: 2em;
    border-bottom: 1px solid $grey-88;

    @include media-tablet {
      border-bottom: none;
      padding-bottom: 0;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 1px;
        width: 100%;
        margin-left: 4.5625em;
        background-color: $grey-88; } }

    @include media-desktop {
      max-width: 16.125em;
      padding-right: 5em;
      margin-right: 5em;
      margin-bottom: 0;
      border-right: 1px solid $grey-88;

      &:after {
        display: none; } }

    img {
      display: block;
      min-width: 10.125em; } }

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    font-size: 1em;

    @include media-tablet-h {
      flex-wrap: nowrap; } }

  &__item {
    width: 100%;
    max-width: 22.5em;
    padding-right: 2em;
    margin-bottom: 1.875em;

    @include media-desktop {
      max-width: 13.3125em;
      margin-bottom: 0; }

    @include media-desktop-1440 {
      max-width: 12.3125em; }

    &--long {
      @include media-desktop-1440 {
        max-width: 16.25em; } }

    &-title {
      @include reset;
      margin-bottom: 1em;
      font-size: 0.875em;
      color: $green-footer; }

    &-name {
      @include reset;
      font-size: 1em;
      font-weight: 400; }

    &-loyalty {
      white-space: nowrap; }

    a {
      display: inline-block;
      margin-top: 0.3em;
      font-size: 0.875em;

      color: white;

      &:hover {
        color: $green-footer; } }

    &-contact-us {
      padding: 0.333em 1.2em;
      background-color: transparent;
      margin-top: 1.2em !important;
      border: 1px solid $green-footer;
      border-radius: 0.4em;

      &:hover {
        color: white !important;
        background-color: $green-footer; } }

    &-loyalty {
      &-tg {
        display: inline-block;
        vertical-align: middle;
        width: 1.666em;
        height: 1.666em;
        margin-left: 0.416em;
        background: url(../images/tg_icon_ft.svg) center no-repeat; } } } }
