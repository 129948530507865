@import "normalize-css/normalize";
*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box;

  font-family: $base-font;
  font-size: 16px;
  line-height: 1.3; }

body {
  min-width: $mobile-width;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  // overflow: hidden
  display: flex;
  flex-direction: column; }

main,
.header {
  overflow: hidden; }

img {
  max-width: 100%;
  font-size: 1em; }

a {
  text-decoration: none;
  color: $dark-grey; }

h2 {
  @include reset;
  @include h48; }

h3 {
  @include reset;
  @include h36; }

#userBar {
  display: none; }

#app,
#root {

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;

  .langing {
    min-height: 100vh; } }

input[type="text"] {
  @include fs14;

  // padding: 0.5714em 0.8571em

  // border-radius: 0.2857em
  border: 1px solid $grey-e5; }

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.text-blue {
  color: $color-red; }

#jaas-container {
  height: 100%; }

.adfox-container {
  position: fixed;
  bottom: 0;
  z-index: 500;
  height: 80px;
  width: 100%; }
