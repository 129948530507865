.prizes {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  background-color: $dark-grey;
  position: relative;

  @include media-tablet {
    padding-top: 4.625rem;
    padding-bottom: 1.25rem;
    overflow: hidden; }

  @include media-tablet-h {
    padding-top: 7rem;
    padding-bottom: 2.375rem; }

  @include media-desktop {
    padding-top: 5em;
    padding-bottom: 0.5rem; }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 19.5em;
    background: $color-red;
    left: 0;
    top: 0;
    display: none;

    @include media-tablet {
      display: block;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      left: 0;
      background-size: cover;
      width: 50%;
      height: 100%; } }

  &__header {
    .cont {
      display: grid;
      row-gap: 1.5em;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        align-items: center; } } }

  &__title {
    @include reset;
    @include h36;
    position: relative;
    z-index: 2;
    color: #ffffff;

    @include media-tablet-h {
      @include h48; }

    @include media-desktop {
      max-width: initial;
      @include h64; } }

  &__desc {
    position: relative;
    z-index: 2;
    color: #fff;
    text-decoration: underline;

    @include media-tablet {
      @include fs18;
      padding: 0 2rem;
      padding-left: 2em;
      padding: 0 0 0 2.1875rem; }

    @include media-tablet-h {
      @include fs24;
      font-weight: 400;
      padding: 0 0 0 3rem; }

    @include media-desktop {
      margin-left: auto;
      padding: 0;
      max-width: 15.75em; }

    @include media-desktop-1600 {
      @include fs18; } }

  &__body {
    .cont {
      @include max-w(768px) {
        width: 100%; } } }

  .swiper-container {
    padding: 2.5rem 0;

    @include media-tablet {
      position: relative;
      padding: 3.75rem 0; }

    @include media-tablet-h {
      padding: 3.125rem 0; }

    @include media-desktop {
      padding: 5rem 0; } }

  .swiper-pagination {
    @include media-tablet {
      display: none; } }

  .swiper-pagination-bullet {
    width: 0.75em;
    height: 0.75em;
    margin: 0 0.75em;
    background-color: #fff;
    opacity: 1; }

  .swiper-pagination-bullet-active {
    background-color: $red; }

  &__slider {
    &-wrapper {
      position: relative;
      width: 100%;
      min-width: 1px;

      .swiper-slide {
        height: 100%; } }

    &-nav {
      position: absolute;
      top: 47.2%;
      z-index: 50;
      display: none;
      width: 2.9375em;
      height: 3.5em;
      background-color: $grey-80;
      background-image: url(@public/images/prizes-slider-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      @include media-tablet {
        display: block; }

      &--prev {
        left: 0;
        transform: translateY(-50%); }

      &--next {
        right: 0;
        transform: translateY(-50%) rotate(180deg);
        border-bottom-right-radius: 0.25em;
        border-top-right-radius: 0.25em; }

      &--disabled {
        display: none; } } }

  &__slide {
    display: grid;
    // grid-template-rows: 12.5625em 10.3125em
    grid-template-rows: 12.5625em 1fr;
    box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-radius: 0.75em;
    overflow: hidden;

    &-content {
      display: grid;
      row-gap: 1.875em;
      align-items: start;
      // grid-template-rows: auto 1fr
      grid-template-rows: 1fr;
      min-height: 6.5625rem;
      padding: 1.875em 1.25em; }

    &-stand {
      @include fs16;
      color: $grey-89;
      font-weight: bold;
      margin: 0; }

    &-bg {
      border-radius: 0.75em;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }

    &-title {
      @include reset;
      @include fs18;

      font-weight: 800;

      a {
        color: $color-red;
        text-decoration: underline; }

      span {
        color: $color-red; } }

    &-link {
      color: $red;
      font-weight: 800;
      align-self: end; } } }
