.about-exh {
  padding-top: 64px;
  padding-bottom: 55px;
  position: relative;

  .cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-tablet-h {
      flex-direction: row; } }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    width: 45%;
    height: 100%;
    border-radius: 110px 0 0 0;
    background: $color-red;
    z-index: -1; }

  @include media-tablet-h {
    border-radius: unset;
    background-color: unset;

    &::after {
      display: block; } }

  @include media-desktop-1440 {
    &::after {
      width: 50%; } } }

.about-exh-left {
  padding-bottom: 30px;

  &__title {
    @include h36; }

  &__desc-wrapper {
    margin-top: 14px; }

  &__desc {
    @include reset;
    @include fs14;
    @include media-tablet-h {
      @include fs18; }
    @include media-desktop-1600 {
      @include fs24; } }

  &__list {
    @include fs14;
    @include p14;
    margin-top: 12px;
    padding-left: 15px;
    @include media-tablet-h {
      @include fs18; }
    @include media-desktop-1600 {
      @include fs24; } }

  &__link {
    @include reset;
    @include fs14;
    margin-top: 30px;
    font-weight: bold;
    @include media-tablet-h {
      @include fs18; }
    @include media-desktop-1600 {
      @include fs24; } }

  @include media-tablet-h {
    width: 45%;
    padding-bottom: 80px;

    &__title {
      margin-bottom: 32px; }

    &__desc {
      margin-bottom: 24px; }

    &__list > li {
      margin-bottom: 12px; }

    &__list > li:last-child {
      margin-bottom: 32px; } } }


.about-exh-right {
  padding: 30px 30px 80px;
  margin-left: -30px;
  margin-right: -30px;
  border-radius: 0 110px 0 0;
  color: #fff;
  background-color: $red;

  &__title {
    @include h24;
    @include media-desktop-1600 {
      @include h36; } }

  &__desc {
    @include reset;
    @include fs18;
    margin-top: 14px;
    @include media-desktop-1600 {
      @include fs24; } }

  &__btn {
    width: 100%;
    margin-top: 30px;
    color: $red;
    border: unset;
    @include fs18; }

  @include media-tablet-h {
    background-color: unset;
    margin-left: unset;
    margin-right: unset;
    padding: unset;
    width: 40%;

    &__title {
      @include h36; } } }
