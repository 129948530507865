.schedule {
  padding-top: 2em;
  padding-bottom: 2em;
  color: $dark-grey;
  background-color: $grey-f0;

  @include media-tablet {
    padding-top: 4.375em;
    padding-bottom: 3.5em; }

  @include media-desktop {
    padding-bottom: 4em; }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__flow {
    padding-top: 0;

    @include media-tablet {
      padding-top: 2.5em; }

    &-title {
      display: grid;
      border-radius: 0.5em;
      color: $grey-c0;
      background-color: $grey-ee;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr); }

      &-item {
        padding: 1.25em;
        text-align: center;
        color: $grey-c0;
        font-weight: 800;

        @include media-tablet {
          @include fs18;
          padding: 1.1111em;
          text-align: left; }

        @include media-tablet-h {
          @include h24;
          padding: 0.8333em 1.6667em; }

        @include media-desktop {
          @include h36;
          padding: 0.6667em 1.1111em;
          text-align: left; }

        &:nth-child(2n) {
          @include media-tablet {
            text-align: right; } }

        &--active {
          color: $dark-grey;
          background-color: #ffffff;
          box-shadow: 0.1111em 0.1111em 0.3333em rgba(0, 0, 0, 0.15);
          border-radius: 0.2222em;

          span {
            color: $red; } } } }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }

    &-desc {
      @include lreset;
      width: 31.111em;

      li {
        @include p16;
        position: relative;
        padding-left: 0.875em;

        &::before {
          content: "";
          position: absolute;
          top: 0.7em;
          left: 0.0625em;
          width: 0.25em;
          height: 0.25em;
          background-color: $dark-grey;
          border-radius: 50%; } } }

    &-view {
      @include fs14;
      position: relative;
      display: none;

      @include media-desktop {
        display: block; }

      &--open {
        .schedule__flow-view-switch {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; }

        .schedule__flow-view-dropdown {
          display: block; } }

      &-switch {
        padding: 0.8571em 1.4286em;
        padding-right: 2em;
        border-radius: 0.25rem;
        border: 1px solid $dark-grey;
        cursor: pointer;
        position: relative;

        &:after {
          width: 0.7em;
          height: 0.7em;
          content: '';
          position: absolute;
          background: url(/public/images/shedule-arrow.svg) no-repeat center;
          background-size: contain;
          right: 1em;
          top: 1.1em; }

        &>span {
          color: $color-red; } }

      &-dropdown {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0.8571em 1.4286em;
        padding-right: 0;
        border: 1px solid $dark-grey;
        border-top: none;
        border-radius: 0 0 2.0625rem 2.0625rem;
        background-color: $grey-f5;

        &-item {
          padding: 0.5em 0;
          cursor: pointer;

          span {
            &:nth-child(1) {
              opacity: 0; }

            &:nth-child(2) {
              color: $grey-80; } } } } } }

  &__title {
    @include h36;
    font-weight: 800;

    @include media-tablet {
      @include h48; } }

  &__filter {
    grid-column: 1 / span 2;
    grid-row: 2;
    margin-top: 3.5em;

    &-group {
      display: grid;
      margin-bottom: 2.5em;
      row-gap: 1em;

      @include media-tablet {
        grid-template-columns: 5em 1fr;
        column-gap: 2em;
        margin-bottom: 1em; }

      @include media-desktop {
        grid-template-columns: auto 1fr;
        column-gap: 1.562em; }

      &:last-of-type {
        margin-bottom: 0; } }

    &-title {
      @include fs18;
      display: flex;
      align-items: center;
      color: $grey-88; }

    &-list {
      @include lreset;
      display: flex;
      flex-wrap: wrap; }

    &-item {
      margin-right: 1em;

      &:last-of-type {
        margin-right: 0; }

      label {
        display: block;
        margin-bottom: 0.5em;
        padding: 0.312em 0.555em;
        background-color: white;
        border-radius: 0.25em; }

      input:checked + label {
        color: white;
        background-color: $red; } } }

  &__list {
    @include lreset;

    margin-top: 2.5em;
    display: grid;
    row-gap: 0.75rem;

    @include media-desktop {
      margin-top: 2.5em; }

    &--hidden {
      display: grid;

      @include media-desktop {
        display: none; } }

    &-day {
      display: grid;
      border-radius: 1.25em;
      overflow: hidden;

      @include media-tablet;

      &-header {
        padding: 1.25em 1.5em;
        min-height: 3.5em;
        color: $color-black;
        background-color: $grey-e5;
        cursor: pointer;

        @include media-tablet {
          padding: 0 2em;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }

        &--archived {
          color: $color-black;
          background-color: $grey-c0; }

        &--networking-day {
          background-color: $color-red;
          color: #fff;

          hr {
            background-color: #fff !important; } } }

      &-date-wrapper {
        @include fs18;

        @include media-tablet {
          font-size: 1.5em;
          line-height: 1.25; } }

      &-date {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.75rem;
        align-items: center;
        grid-auto-columns: max-content;

        span {
          &:last-child {
            text-transform: capitalize; } }

        hr {
          @include reset;
          height: 70%;
          width: 2px;
          border: none;
          background-color: $color-black; } }

      &-time {
        position: relative;
        padding-left: 1.25em;
        font-weight: 800;

        &::before {
          content: "";
          position: absolute;
          top: 0.2em;
          bottom: 0.2em;
          left: 0.62em;
          width: 0.083em;
          background-color: $dark-grey; } }

      &-timezone {
        position: absolute;
        top: 0;
        right: -0.25em;
        transform: translateX(100%);
        font-size: 0.583em; }

      &-body {
        display: grid;
        row-gap: 0.5em;
        max-height: 400em;
        background-color: $grey-f5;
        transition: all 0.3s;

        @include media-tablet {
          row-gap: 0.75em; }

        @include media-tablet-h {
          row-gap: 0.5em; }

        @include media-desktop {
          row-gap: 0.25em; } }

      &--hidden {
        .schedule__list-day {
          &-header {
            border-radius: 1.25em; }

          &-body,
          &-netw-body {
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
            max-height: 0; } } } } }

  &__item {
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0; }

    &-flow {
      @include fs14;
      margin-top: 0.571em;
      font-weight: 800;

      @include media-tablet {
        @include fs18;
        margin-top: 0; } }

    &-body {
      display: grid;
      padding: 2em 1.5em 1.5em;
      grid-template-columns: 100%;
      grid-template-areas: "date" "content" "company" "slider" "btn";
      row-gap: 0.75em;
      background-color: #ffffff;

      // overflow: hidden

      @include media-tablet {
        grid-template-areas: "date content" "company company" "slider slider" "btn btn";
        grid-template-columns: 6.8125em 1fr;
        column-gap: 1em;
        row-gap: 1.875em; }

      @include media-tablet-h {
        grid-template-areas: "date content btn" "company company company" "slider slider slider";
        grid-template-rows: auto;
        grid-template-columns: 9.0625em 1fr 13.1875em;
        column-gap: 1em;
        padding: 2.5em 2em 1.5em 2em; }

      @include media-desktop {
        grid-template-columns: 9.5625em 1fr 16.5em;
        row-gap: 2.5em; } }

    &-date {
      @include h36;

      @include media-tablet {
        @include h24; }

      @include media-tablet-h {
        @include h36; }

      &-timezone {
        position: relative;
        top: -1em;
        right: -0.5em;
        font-size: 0.4444em;
        text-transform: uppercase;

        @include media-tablet {
          font-size: 0.4583em; }

        @include media-tablet-h {
          font-size: 0.4444em; } }

      &-block {
        grid-area: date; } }

    &-content {
      grid-area: content;
      display: grid;
      row-gap: 0.75em;
      margin-bottom: 1.125em;

      @include media-tablet {
        margin-bottom: 0; }

      @include media-tablet-h {
        padding-right: 3rem; }

      @include media-desktop {
        padding-right: 1.5rem; } }

    &-company {
      grid-area: company;
      margin-bottom: 1.125em;
      border-top: 1px solid $grey-e5;

      @include media-tablet {
        margin-bottom: 0; }

      @include media-tablet-h {
        margin-bottom: 0.625rem; }

      @include media-desktop {
        position: relative;
        margin-bottom: 0;
        border: none; }

      &::before {
        @include media-desktop {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-1px);
          width: 100%;
          height: 1px;
          background-color: $grey-e5; } }

      &-title {
        @include fs14;
        padding-top: 0.7143em;
        color: $grey-c0;

        @include media-tablet-h {
          @include fs16;
          padding-top: 1.125em; }

        @include media-desktop {
          position: relative;
          display: inline-block;
          padding-top: 0;
          padding-right: 1.5em;
          background-color: #ffffff; } } }

    &-title {
      @include reset;
      @include fs14;
      font-weight: 800;

      @include media-tablet {
        @include fs18; }

      @include media-desktop {
        @include fs16; } }

    &-desc {
      @include reset;
      @include p14;

      @include media-tablet {
        @include p18;
        margin-top: 0; }

      @include media-desktop {
        @include p16; }

      p {
        margin: 0.666em 0;

        &:first-of-type {
          margin-top: 0; }

        &:last-of-type {
          margin-bottom: 0; } } }

    &-longdesc {
      @include p14;
      padding: 0 0.8571em;
      height: 0;
      max-height: 0;
      color: $color-black;
      background-color: $grey-f0;
      overflow: hidden;
      transition: all 0.3s ease;

      p {
        margin: 0.75rem 0; }

      ol,ul {
        @include reset;
        margin: 0.5rem 0;
        padding-left: 1.25rem;

        li {
          margin: 0.25rem 0; } }

      &--active {
        padding: 0.8571em;
        margin-top: -0.75rem;
        height: auto;
        max-height: 300em;
        border-radius: 0 1.25rem 1.25rem 1.25rem; } }

    &-more-btn {
      @include reset;
      @include fs14;
      padding: 0.2857em 1.4286em;
      justify-self: start;
      margin-bottom: -0.75rem;
      background-color: $grey-f0;
      border-radius: 1.4286em;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;

      @include media-tablet;

      &--opened {
        margin-bottom: 0;
        border-radius: 1.4286em 1.4286em 0 0; } }

    &-btn-block {
      grid-area: btn; }

    &-participants {
      @include fs14;
      margin-top: 1.142em;
      text-align: center;
      color: $grey-88;

      span {
        color: $dark-grey; } }

    &-slider-block {
      position: relative;
      grid-area: slider;
      margin-bottom: 0.75rem;

      @include media-tablet {
        margin-bottom: 1.125rem; } }

    &-slider-wrapper {
      position: relative;
      padding-left: 0.5em;
      padding-right: 0.5em;

      .swiper-button-disabled {
        display: none; } }

    .swiper-slide {
      width: 13.875em; }

    &-slider {
      position: relative;

      &-nav {
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        width: 2.5em;
        height: 3.5em;
        background-color: $grey-e5;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-slider-arrow.svg");
          background-repeat: no-repeat;
          background-position: center; }

        &--disabled {
          visibility: hidden; } }

      &-prev {
        left: -2em;
        border-radius: 0.375em 0 0 0.375em;

        &::before {
          transform: rotate(180deg); } }

      &-next {
        right: -2em;
        border-radius: 0.375em 0 0 0.375em; } }

    &-slide {
      display: flex;
      color: $dark-grey;

      &-img {
        margin-right: 1em;
        width: 3.5em;
        height: 3.5em;
        flex-shrink: 0;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          overflow: hidden; } }

      &-content {}

      &-title {
        font-weight: 800; }

      &-desc {
        margin-top: 0.363em;
        font-size: 0.6875em;
        line-height: 1.35; } }

    &-netw {
      &-body {
        display: grid;
        row-gap: 1.5em;
        max-height: 400em;
        padding: 2.5rem 2rem;
        background-color: #ffffff;
        transition: all 0.3s;

        @include media-tablet-h {
          grid-template-columns: 1fr 13.1875rem;
          column-gap: 5rem; }

        @include media-desktop {
          grid-template-columns: 1fr 14.5rem; } }

      &-content {
        display: grid;
        row-gap: 0.75rem;

        @include media-tablet {
          grid-template-columns: 18.75rem 1fr;
          column-gap: 1rem; }

        @include media-tablet-h {
          grid-template-columns: 1fr; } }

      &-title {
        @include reset;
        @include h36; }

      &-desc {
        @include reset;
        @include p16;

        @include media-tablet {
          grid-column: 2; }

        @include media-tablet-h {
          @include p18;
          grid-column: 1; } }

      &-btn {
        @include media-tablet {
          grid-column: span 2; }

        @include media-tablet-h {
          display: flex;
          align-items: center;
          grid-column: 2 / span 1; }

        .btn {
          width: 100%;
          max-width: 10.875em; } } } }

  &__table {
    display: none;

    @include media-desktop {
      display: block; }

    &--hidden {
      display: none; }

    &-filter {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-content: end;
      column-gap: 1.5625em;

      &-title {
        @include fs18;
        margin-bottom: 0.75em;
        color: $grey-88;
        text-align: right; }

      &-list {
        display: flex;
        flex-wrap: wrap; }

      &-item {
        padding: 0.3125em 0.5625em;
        margin-bottom: 0.75em;
        margin-right: 0.75em;
        border-radius: 0.25rem;
        background-color: #ffffff;
        cursor: pointer;
        user-select: none;

        &--active {
          color: #ffffff;
          background-color: $color-red; } } }

    &-week {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 0.5em;
      margin-top: 2.5em; }

    &-day {
      position: relative;
      display: grid;
      row-gap: 0.125em;
      border-radius: 0.75em;
      overflow: hidden; }

    &-header {
      @include fs14;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.174em;
      padding-right: 5em;
      color: $color-black;
      background-color: $grey-e5;

      &--networking-day {
        font-weight: 800;
        background-color: $color-red;
        color: #fff; }

      &-logo {
        position: absolute;
        right: 0;
        top: 0;
        width: 5em;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; } } }

    &-bg-wrapper {
      position: absolute;
      top: 2.625em;
      left: 0;
      right: 0;
      display: grid;
      row-gap: 0.125em; }

    &-bg {
      padding: 1.5em;
      grid-row: auto / span 1;
      background-color: white;

      &-time {
        @include reset;
        position: relative;
        display: inline-block;
        color: $grey-ee;
        font-weight: 800;

        span {
          @include fs11;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(110%); } } }

    &-item-wrapper {
      position: absolute;
      top: 2.625em;
      left: 0;
      right: 0;
      display: grid;
      row-gap: 0.125em; }

    &-item {
      display: flex;
      flex-direction: column;
      padding: 1.5em;
      overflow: hidden;
      background-color: white;

      &-header {
        display: flex;
        justify-content: space-between;
        font-weight: 800; }

      &-date {
        position: relative;
        font-weight: 800;

        span {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(110%);
          @include fs11; } }

      &-flag {
        @include fs11;
        width: 44.7%;
        text-align: right;
        font-weight: 800;
        color: $color-red; }

      &-desc {
        @include reset;
        margin-top: 0.75em;
        overflow: hidden;
        color: $color-black;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        font-size: 0.8125em; }

      &-bottom {
        margin-top: auto;

        .btn {
          margin-left: auto; } }

      &-btn {
        @include reset;
        @include fs11;
        width: 10.454em;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: $grey-88;
        text-align: right;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:focus {
          outline: none; }

        * {
          pointer-events: none; }

        &>span {
          &:first-of-type {
            display: block; }
          &:last-of-type {
            display: none; } }

        &.processed {
          opacity: 0.6; }

        &:hover {
          .schedule__table-item-btn-icon {
            background-color: $color-red;

            &::before,
            &::after {
              background-color: white; } } }

        &.subscribed {
          &>span {
            &:first-of-type {
              display: none; }

            &:last-of-type {
              display: block; } }

          .schedule__table-item-btn-icon {
            border-color: $grey-c0;

            &::before,
            &::after {
              background-color: $grey-88; }

            &::before {
              width: 0.545em;
              transform: translateX(-0.25em) rotate(45deg); }

            &::after {
              transform: translateX(0.25em) rotate(135deg); } }

          &:hover {
            .schedule__table-item-btn-icon {
              background-color: #ffffff;

              &::before {
                width: 0.909em;
                transform: translateX(0) rotate(45deg); }

              &::after {
                transform: translateX(0) rotate(135deg); } } } }

        &-icon {
          position: relative;
          width: 3.09em;
          height: 3.09em;
          margin-left: 0.9875em;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $red;
          border-radius: 0.3636em;
          transition: all 0.2s ease-out;

          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 0.909em;
            height: 0.182em;
            background-color: $red;
            border-radius: 1em;
            transition: all 0.2s ease-out; }

          &::after {
            transform: rotate(90deg); } }

        &--look {
          .schedule__table-item-btn-icon {
            background-color: #ffffff;
            border-color: $grey-c0;

            &::before,
            &::after {
              display: none; }

            svg,
            path {
              fill: $red; } }

          &:hover {
            .schedule__table-item-btn-icon {
              background-color: $red;
              border-color: $red;

              svg,
              path {
                fill: #ffffff; } } } } }

      &-company {
        @include fs11;
        margin-top: 1.0909em;
        color: $color-red;
        font-style: italic;
        font-weight: 600; }

      &-netw {
        display: grid;
        row-gap: 1.25rem;
        grid-template-rows: repeat(2, max-content);

        &-content {
          display: grid;
          row-gap: 0.5rem; }

        &-title {
          @include reset;
          font-weight: 800; }

        &-desc {
          @include reset;
          color: $color-black;
          font-size: 0.8125rem; }

        &-btn {
          .btn {
            width: 100%; } } } } }

  &__expert {
    display: grid;
    grid-template-columns: 3.5rem 1fr;
    grid-gap: 0.25rem 1rem;

    &-photo {
      width: 3.5em;
      height: 3.5em;
      grid-row: 1 / span 2;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden; }

    &-name {
      align-self: end;
      font-weight: 800; }

    &-workplace {
      font-size: 0.6875em;
      line-height: 1.35; } } }

@for $i from 1 through 48 {
  .schedule__table[data-period-count="#{$i}"] {
    .schedule__table-item-wrapper {
      @include media-desktop {
        grid-template-rows: repeat($i, 8.5em); } }

    .schedule__table-bg-wrapper {
      @include media-desktop {
        grid-template-rows: repeat($i, 8.5em); } }

    .schedule__table-day {
      @include media-desktop {
        grid-template-rows: 2.625em repeat($i, 8.5em); } }

    &[data-period-length="60"] {
      .schedule__table-item-wrapper {
        @include media-desktop {
          grid-template-rows: repeat($i, 17em); } }

      .schedule__table-bg-wrapper {
        @include media-desktop {
          grid-template-rows: repeat($i, 17em); } }

      .schedule__table-day {
        @include media-desktop {
          grid-template-rows: 2.625em repeat($i, 17em); } } } } }

@for $i from 1 through 48 {
  .schedule__table-item[data-event-duration="#{$i}"] {
    grid-row-end: span $i; } }

@for $i from 1 through 48 {
  .schedule__table-item[data-start-time="#{$i}"] {
    grid-row-start: $i; } }

// ! Кастомное брендирование
//// * date_134 - Греция
//// * date_194 - Беларусь

.schedule__list-day {
  &#date_134 {
    .schedule__item {
      color: #ffffff;

      &-header {
        background-color: #20ADE4;

        @include media-tablet {
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-greece.svg");
          background-repeat: no-repeat;
          background-position: right 1em center; } }

      &-time {
        &::before {
          background-color: #ffffff; } } } }

  &#date_194 {
    .schedule__item {
      color: #ffffff;

      &-header {
        background-color: #234B97;

        @include media-tablet {
          background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-bellarus.svg");
          background-repeat: no-repeat;
          background-position: right center;
          background-size: contain; } }

      &-time {
        &::before {
          background-color: #ffffff; } } } } }

.schedule__table-day {
  &#date_134 {
    .schedule__table-header {
      color: #ffffff;
      background-color: #20ADE4;
      background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-greece.svg");
      background-repeat: no-repeat;
      background-size: auto 12px;
      background-position: right 11px center; } }

  &#date_194 {
    .schedule__table-header {
      color: #ffffff;
      background-color: #234B97;
      background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-table-bellarus.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right center; } } }

.shedule__list-day-date-name {
  display: none;

  @include media-tablet-h {
    display: inline; } }

.schedule__list-day {
  .schedule__list-day-header--lux {
    background: linear-gradient(90deg, #B08438 0, #EDCB82 100%);
    position: relative;
    color: #fff;
    overflow: hidden;

    hr {
      background-color: #fff; } } }

.schedule__list-day {
  .schedule__list-day-header--bel {
    background: #244B96;
    position: relative;
    color: #fff;
    overflow: hidden;

    hr {
      background: #fff; }

    @include media-tablet {
      &::after {
        position: absolute;
        content: '';
        width: 12.5625em;
        height: 7.125em;
        background: url(@public/images/shedule-bel.png) no-repeat center;
        right: -1em;
        top: -1.25em; } } } }

.schedule__table-day {
  &-header--bel {
    background: #244B96;
    position: relative;
    color: #fff;
    font-weight: 400;

    @include media-tablet {
      &::after {
        position: absolute;
        content: '';
        width: 5.8em;
        height: 3em;
        background: url(@public/images/bel-table.png) no-repeat center;
        right: 0em;
        top: 0;
        background-size: contain; } }

    hr {
      background: #fff; } } }
