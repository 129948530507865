.partners {
  padding: 2.5em 0 3.4375em;
  background-color: $light-grey;

  @include media-tablet {
    padding: 2.5em 0; }

  @include media-desktop-1600 {
    padding: 3.3em 0; }

  &__group {
    display: flex;
    align-items: center;

    @include media-tablet {
      margin-bottom: 0; }

    @include media-desktop {
      margin-right: 0em; }

    &-desktop {
      display: none;

      @include media-desktop-1600 {
        display: flex;
        justify-content: space-between;
        column-gap: 0;
        row-gap: 0; } }

    &-mobile {
      display: flex;
      flex-direction: column;

      @include media-tablet-h {
        justify-content: space-between;
        align-items: center;
        flex-direction: row; }

      @include media-desktop-1600 {
        display: none; } }

    &--one-item {
      display: flex;
      column-gap: 0; }

    &--many-items {
      display: grid;

      @include media-tablet-h {
        grid-template-columns: auto 1fr;
        column-gap: 1.5em; }

      .partners {
        &__title {
          text-align: center;

          @include media-tablet {
            text-align: left; }

          @include media-tablet-h {
            padding-bottom: 0; }

          br {
            display: none;

            @include media-tablet-h {
              display: inline; } } }

        &__list {
          @include media-desktop {
            display: flex; } } } }

    &--org {
      grid-row: 1;
      margin-bottom: 1em;
      justify-content: center;
      column-gap: 1.5em;

      @include media-tablet-h {
        margin-bottom: 0; }

      .partners__title {
        @include media-tablet {
          width: auto; } } }

    &--sup {
      display: none;
      grid-row: 1;
      margin-bottom: 1em;
      flex-direction: column;

      @include media-tablet {
        grid-column: 1;
        flex-direction: row;
        margin-bottom: 0; } }

    &--support {
      display: flex;
      margin-bottom: 0;
      flex-wrap: wrap;
      justify-content: center;

      @include media-tablet-h {
        grid-row: 3; }

      .partners {
        &__list {
          display: grid;
          grid-template-areas: "title item1" "item2 item2";
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          align-items: center;

          @include media-tablet {
            display: flex; } }

        &__title {
          grid-area: title; }

        &__item {
          width: auto;
          max-width: 13.375rem;
          height: 4.375rem;

          &-1 {
            grid-area: item1; }

          &-2 {
            grid-area: item2; }

          &-3 {
            grid-area: item3; } } } }

    &--sponsor {
      grid-row: 2;

      @include media-tablet {
        grid-row: 1;
        grid-column: 2;
        justify-self: end; } } }

  &__title {
    font-size: 0.7921em;
    color: $grey;
    line-height: 1;

    @include media-tablet-h {
      width: initial; }

    &--sup {
      @include media-tablet-h {
        max-width: 6.625em; } } }

  &__list {
    @include lreset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 1.5em;

    @include media-tablet-h {
      justify-content: center;
      width: initial;
      column-gap: 1.5em; } }

  &__item {
    width: 5.625em;
    height: 3.375em;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transform: translateZ(0); }

    &-1 {}
    &-3 {
      img {
        transform: none; } }

    &-4 {
      margin-left: 0.5em;
      margin-right: 0.5em;
      @include media-tablet {
        margin-left: 0;
        margin-right: 0; } } } }
